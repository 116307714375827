<template>
  <div class="booklistbox">
    <p v-if="showNomore" class="nomore">暂无数据</p>
    <ul class="booklist" v-if="datalist.length>0">
      <li v-for="item in datalist">
        <div class="onebox">
          <router-link
            tag="div"
            :to="{name:'bookIntroduction', params:{name:item.Identify}}"
            class="imgbox"
          >
            <img :src="item.Cover" alt />
          </router-link>
          <h2 class="titleh2">{{item.Bookname}}</h2>
          <div class="setbox" v-if="set">
            <i class="tag warntag" @click="handelPublishBook(item.Id)" v-if="item.Status==2">待发布</i>
            <i class="tag successtag" v-if="item.Status==1">已发布</i>
            <i class="tag info" v-if="item.Status==3">审核中</i>
            <i class="tag info" v-if="item.Status==4">已删除</i>
            <span class="del iconfont iconshanchu3" @click="handelDelBook(item.Id)"></span>
            <router-link
              :to="tid ? {name:'bookInfo',query:{Id:item.Id,tid:tid}}: {name:'bookInfo',query:{Id:item.Id}}"
              tag="span"
              class="edit iconfont iconbianji2"
            ></router-link>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { booksLeaveRequest, booksPublishRequest } from "@/api/project.js";
export default {
  props: {
    set: {
      set: Boolean,
      required: false
    },
    datalist: {
      datalist: Object,
      required: false
    }
  },
  data() {
    return {
       showNomore:false
    };
  },
  computed: {
    tid() {
      return this.$route.params.tid || "";
    }
  },
  methods: {
    async delBook(id) {
      try {
        var _this = this;
        const result = await booksLeaveRequest({
          bookid: id
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: "删除成功!"
          });

          this.datalist.some((item, index, arr) => {
            if (id === item.Id) {
              _this.datalist.splice(index, 1);
            }
            return id === item.Id;
          });
        } else {
          this.$message({
            type: "error",
            message: result.message
          });
        }
      } catch (err) {}
    },
    handelDelBook(id) {
      var _this = this;
      this.$confirm("此操作将永久删除该图书, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.delBook(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async publishBook(id) {
      try {
        const result = await booksPublishRequest({
          bookid: id
        });
        if (result.status == 200 || result.status == 2020) {
          this.$message({
            type: "success",
            message: result.message
          });
        } else {
          this.$message({
            type: "error",
            message: result.message
          });
        }
      } catch (err) {}
    },
    handelPublishBook(id) {
      var _this = this;
      console.log(id);
      this.$confirm("确认发布该图书, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          _this.publishBook(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发布"
          });
        });
    }
  },
  watch: {
     datalist(val) {
      if(val.length==0)this.showNomore=true;
    }
  }
};
</script>
<style  lang="less" scoped>
.booklistbox {
  min-height: 480px;
}
.booklist {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  & > li {
    width: 16.6667%;
    padding: 0 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
    .onebox {
      background: #fff;
      -webkit-box-shadow: 0px 0px 10px #e0e0e0;
      box-shadow: 0px 0px 10px #e0e0e0;
      padding: 10px;
    }
    .imgbox {
      border: 1px solid #e5e5e5;
      padding: 2px;
      cursor: pointer;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .titleh2 {
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px;
      color: #666;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .setbox {
      overflow: hidden;
      margin-top: 5px;
    }
    .iconfont {
      float: right;
      color: #11adcf;
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer;
    }
    .tag {
      border-radius: 50px;
      font-size: 11px;
      text-align: center;
      color: #fff;
      float: left;
      padding: 0 5px;
      cursor: pointer;
      &.warntag {
        background: #e6a23c;
      }
      &.successtag {
        background: #6774cd;
      }
      &.info {
        background-color: #f4f4f5;
        color: #909399;
      }
    }
  }
}
.nomore{
  height:150px;
  line-height: 150px;
  text-align: center;
  font-size: 14px;
  color: #999;
}
</style>